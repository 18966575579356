<template>

  <div class="inner">
    <div class="controls">
      <div class='options_block'>
        <div class='toggle-btns flex w_100 py-2'>
          <div class='btn w_100 text-center p-2 pointer border'
               :class='{active: options_data.mode === "short"}'
               @click='options_data.mode="short"'
          >Shortest</div>
          <div class='btn w_100 text-center p-2 pointer border'
               :class='{active: options_data.mode === "fast"}'
               @click='options_data.mode="fast"'
          >Fastest</div>

        </div>
      </div>
      <div class='options_block'>
        <div class='title pt-3'>
          Avoid
        </div>
        <div class='grid_2_cols'>
          <div class="difficult_turnsMain  w-full" v-if='!hide_difficult_turns'>
            <div id="difficult_turns" class="flex items-start justify-between gap-5p w-full">
              <input
                  @change='emitOptions'
                  class="w-2/12" type="checkbox" v-model="options_data.difficult_turns"/>
              <span class="label w-full">Difficult Turns</span>
            </div>
          </div>
          <div class="u_turnsMain w-full"
               v-if='!hide_u_turns'>
            <div id="u_turns" class="flex items-start justify-between gap-5p w-full">
              <input
                  @change='emitOptions'
                  class="w-2/12" type="checkbox" v-model="options_data.u_turns"/>
              <span class="label w-full">U Turns</span>
            </div>
          </div>
          <div class="toll_roadMain flex w-full">
            <div id="toll_road" class="flex items-start justify-between gap-5p w-full">
              <input
                  @change='emitOptions'
                  class="w-2/12" type="checkbox" v-model="options_data.toll_road">
              <span class="label w-full">Toll roads</span>
            </div>
          </div>
          <div class="tunnel  w-full">
            <div id="tunnel" class="flex items-start justify-between gap-5p w-full">
              <input
                  @change='emitOptions'
                  class="w-2/12" type="checkbox" v-model="options_data.tunnel"/>
              <span class="label w-full">Tunnels</span>
            </div>
          </div>

          <div class="motorway  w-full">
            <div id="motorway" class="flex items-start justify-between gap-5p w-full">
              <input
                  @change='emitOptions'
                  class="w-2/12" type="checkbox" v-model="options_data.motorway"/>
              <span class="label w-full">Motorways</span>
            </div>
          </div>

          <div class="trafficData  w-full">
            <div id="trafficData" class="flex items-start justify-between gap-5p w-full">
              <input
                  @change='emitOptions'
                  class="w-2/12" type="checkbox" v-model="options_data.trafficData"/>
              <span class="label w-full">Traffic Data</span>
            </div>
          </div>
          <div class="excludedRoads  w-full">
            <div id="excludedRoads" class="flex items-start justify-between gap-5p w-full">
              <input
                  @change='emitOptions'
                  class="w-2/12" type="checkbox" v-model="options_data.excludedRoads"/>
              <span class="label w-full">My Exclusions</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PlanOptions',
  props: {
    reset_all: {
      type: Boolean,
      default: false
    },
    route_options: {
      type: Object,
    },
    transport_type: {
      type: String
    }
  },
  data() {
    return {
      hide_difficult_turns: false,
      hide_u_turns: false,
      options_data: {
        toll_road: false,
        difficult_turns: false,
        u_turns: false,
        motorway: false,
        mode: 'short',
        trafficData: false,
        excludedRoads: true,
      }
    }
  },
  methods: {
    emitOptions() {
      this.$emit('push_options', this.options_data)
    },
    init() {
      setTimeout(() => {
        if (this.route_options) {
          this.options_data = this.route_options;
        }
      }, 500)
    }
  },
  async mounted() {
    await this.init();
  },
  watch: {
    'transport_type': function (val) {
      console.log(val)
      if (['bus', 'privateBus'].includes(val)) {
        this.hide_difficult_turns = true
        this.options_data.difficult_turns = false
      } else if (val === 'taxi' || val === 'car') {
        this.hide_difficult_turns = true
        this.hide_u_turns = true
        this.options_data.difficult_turns = false
        this.options_data.u_turns = false
      } else {
        this.hide_difficult_turns = false
        this.hide_u_turns = false
      }
    },
    reset_all() {
      this.options_data = {
        toll_road: false,
        difficult_turns: false,
        tunnel: false,
        u_turns: false,
        mode: 'short',
        trafficData: false,
        excludedRoads: true
      }
    }
  }
}
</script>
<style scoped lang="scss">
#closeZoneIcon {
  max-width: 20px;
}
.btn {
  transition: .2s;
  padding: 10px;
  &.active {
    color: #1FBC2F;
    background: #fff;
    font-weight: bold;
    border: 1px solid #1FBC2F;
    transition: .2s;
  }
}
.closeZone{
  cursor: pointer;
  border-bottom: 1px solid #000;
  padding-top: 8px;
}
.options_block {
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.radio_group {
  display: flex;
  align-items: start;
  flex-direction: column;
  gap: 0px;
  .input_group{
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: center;
  }
  input {
    width: 20px;
  }
}
.label {
  text-align: left;
}
.title {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
}
.inner.control {
  padding: 3px;
  position: absolute;
  top: 250px;
  right: 50px;
  background: #fff;
  border-radius: 5px;
  .controls {
    min-width: 200px;
  }
}
.grid_2_cols {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
</style>